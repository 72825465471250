<script setup>
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
</script>

<template>
    <v-row id="index" class="justify-center">
        <!-- Carousel and Sidebar -->
        <v-col cols="12" sm="10" class="mt-4">
            <v-row>
                <v-col cols="12" md="8">
                    <lazy-ad-carousel spot="carousel" page="home" position="top" class="slide-from-left" />
                </v-col>
                <v-col cols="12" md="4">
                    <lazy-ad-sidebar
                        spot="sidebar"
                        page="home"
                        :placing="mdAndUp ? 12 : 6"
                        position="top"
                        class="slide-from-right"
                    />
                </v-col>
            </v-row>
        </v-col>
        <!-- Featured Products -->
        <v-col cols="12" sm="12" class="slide-from-right">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-products-featured />
            </v-lazy>
        </v-col>
        <!-- Featured Deals -->
        <v-col cols="12" sm="12" class="mb-1 slide-from-left">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-deals-featured />
            </v-lazy>
        </v-col>
        <!-- Spotlight -->
        <v-col cols="12" sm="12" class="pa-0 slide-from-right">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-ad-spotlight spot="spotlight" page="home" position="center" />
            </v-lazy>
        </v-col>
        <!-- Product Matrix -->
        <v-col cols="12" sm="10" class="slide-from-left">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-products-matrix />
            </v-lazy>
        </v-col>
        <!-- Center -->
        <v-col cols="12" sm="10" class="my-16 slide-from-right">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-ad-body spot="center" page="home" status="Paid" position="bottom" />
            </v-lazy>
        </v-col>
        <!-- Disclosure -->
        <v-col cols="12" sm="10" class="fade-in-up">
            <v-lazy :options="{ threshold: 0.5 }">
                <lazy-ad-disclosure />
            </v-lazy>
        </v-col>
    </v-row>
</template>

<style scoped></style>
